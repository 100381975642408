.primaryBg {
  background-image: url('../../assets/images/bg.png');
  background-attachment: fixed;
  text-align: center;
}

#auth-layout-compment {
  min-height: 100vh;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

// .logo {
//   background-image: url('../../assets/images/logo-150.png');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 150px;
//   width: 100%;
//   height: 100px;
// }

.site-drawer-render-in-current-wrapper {
  position: static;
  padding: 48px;
  overflow: hidden;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.page-title {
  margin-bottom: 1.5em !important;
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;