.profile-feature {
  padding: 10%;

  @media screen and (max-width: 420px) {
    padding-top: 40%;
    padding-left: 20px;
    padding-right: 20px;
  }


  &-display {
    background-color: white;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border-radius: 20px;

    @media screen and (max-width: 420px) {
      width: 100%;
    }

    &-qrcode {
      width: 150px;
      margin: 16px auto;
    }

    &-invitation-key {}
  }

}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;