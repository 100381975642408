.main-menu {
  ul.navbar-nav {
    li.nav-item {
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          margin-right: 12px;
        }

        &:active {
          background-color: transparent !important;
        }
      }
    }
  }
}

.main-menu.show {
  padding: 12px;
  background-color: #f9f9f9;
  margin-top: 12px;
  border-radius: 12px;

  ul.navbar-nav {
    li.nav-item {
      padding: 0 !important;
      border-bottom: 1px solid #f0f0f0;

      a {
        padding: 12px 16px;
      }
    }
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;