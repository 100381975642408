.ant-upload-list-picture-card {
    .ant-upload-list-item-info {
        &::before {
            left: 0;
        }
    }
}

.ant-layout-sider {
    background-color: white !important;
}

.ant-input,
.ant-btn,
.ant-input-number-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-group-addon {
    border-radius: 10px;
}

.ant-layout {
    background-color: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6px 11px;
    height: auto !important;
}

.ant-input-group-addon {
    background-color: transparent;
}

.ant-input-group .ant-input {
    padding: 6px 11px !important;

}

.ant-modal-confirm-btns {
    .ant-btn {
        padding: 6px 12px !important;
        border-radius: 10px;
        height: auto;
        min-width: 50px;
    }
}

a.ant-btn-link {
    color: var(--brand-primary-blue);
}