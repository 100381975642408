@import "./variables";
@import '~antd/dist/antd.less';

@import "./font";
@import "./icons";
@import "./typography";

@import "./antd-override.less";

html,
body {
  width: 100%;
  min-height: 100%;
  background: var(white);
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #707070;
  font-weight: 400;
  line-height: 1.75;
}

#root,
.App,
.blank-layout,
.auth-layout {
  min-height: 100%;
}

img {
  max-width: 100%;
}

// .App {
//   background-image: url('../../assets/images/bg.png');
//   background-attachment: fixed;
//   // background: linear-gradient(177deg, rgba(255,101,99,0.6489189425770308) 0%, rgba(20,117,188,1) 50%);
//   text-align: center;
// }


.container {
  width: @container-width;
  margin: 0 auto;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }

  .logo {
    padding: 50px 0 20px;
  }
}

.camera-box {
  background-color: var(--natural-super-light-gray);
  border: 1px dashed var(--natural-light-gray);
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i.fa-camera {
    font-size: 25px;
  }
}

.gig-upload-btn {
  align-items: center;
  background-color: var(--natural-super-light-gray);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  width: 100px;
}

.gig-upload-btn.bordered {
  border: 1px dashed var(--natural-light-gray);
}

.gig-file-field {
  display: none;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .logo {
    margin-bottom: 32px;
  }
}

.center-vh {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.gig-context-header {
  margin-bottom: 1.5rem;

  &--title {
    text-align: left;
  }

  &--action {
    text-align: right;
  }

}

@avatar-size: {
  xl: 96px;
  lg: 72px;
  md: 32px;
  sm: 24px;
  xsm: 12px;
}

;

each(@avatar-size, {
  .avatar-@{key} {
    width: @value  !important;
    height: @value  !important;
    border-radius: 50%;
  }
});

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--natural-charcoal);
  color: var(--natural-charcoal);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
  margin: 5px auto;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--natural-charcoal);
  color: var(--natural-charcoal);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--natural-charcoal);
  color: var(--natural-charcoal);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--natural-charcoal);
  }

  50%,
  100% {
    background-color: var(--natural-gray);
  }
}

.logo-xsm {
  width: 50px;
}

.logo-sm {
  width: 75px;
}

.logo-md {
  width: 150px;
}

.logo-lg {
  width: 250px;
}

.logo-lsg {
  width: 512;
}

.bottom-img img,
.bottom-img-absolute img {
  width: 100%;
}

.bottom-img-absolute {
  bottom: 0px;
  width: 100%;
  position: absolute;
}

.background-img {
  position: relative;
  width: 100%;
  display: block;

  &:before {
    position: absolute;
    content: "";
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(20, 117, 188, 0.85) 0%, rgba(255, 101, 99, 0.85) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;