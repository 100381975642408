@brand-primary-red: #FF6563;
@brand-primary-blue: #1475BC;
@brand-primary-green: #75CA67;
@brand-primary-orange: #FFCA63;

@natural-charcoal: #535353;
@natural-gray: #7E7E7E;
@natural-light-gray: #DDDDDD;
@natural-super-light-gray: #F9F9F9;

@success: #4DF18F;
@error: #C52E2E;
@warning: #C5912E;
@info: #2EA1C5;

@container-width: 1200px;

:root {
  --brand-primary-red: @brand-primary-red;
  --brand-primary-blue: @brand-primary-blue;
  --brand-primary-green: @brand-primary-green;
  --brand-primary-orange: @brand-primary-orange;

  --natural-charcoal: @natural-charcoal;
  --natural-gray: @natural-gray;
  --natural-light-gray: @natural-light-gray;
  --natural-super-light-gray: @natural-super-light-gray;

  --success: @success;
  --error: @error;
  --warning: @warning;
  --info: @info;
}