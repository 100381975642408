h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: @montserrat;
    margin: 0 0 1rem;
    font-weight: 600;
    line-height: 1.21;
    color: initial;
}


@size: {
    1: 3.052em;
    2: 2.441em;
    3: 1.953em;
    4: 1.563em;
    5: 1.25em;
    6: 1.25em;
}

;

each(@size, {
    h@{key} {
        font-size: @value  !important;
    }
});


p,
.p1,
.p2 {
    font-family: @montserrat;
}

p {
    font-weight: normal;
    font-size: 0.813rem;
    // line-height: 1.438rem;
    margin-bottom: 1.25em;
}

.p1,
.p2 {
    font-weight: 300;
    font-size: 0.75rem;
}

.p1 {
    line-height: 1.563rem;
}

.p2 {
    line-height: 0.938rem;
}

strong,
b {
    font-weight: 500 !important;
}