.register-feature {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  // justify-content: center;
  padding-top: 50px;
  background-image: url('../../../assets/images/bg.png');
  background-attachment: fixed;
  // background: linear-gradient(177deg, rgba(255, 101, 99, 0.6489189425770308) 0%, rgba(20, 117, 188, 1) 50%);
  text-align: left;


  @media screen and (max-width: 420px) {
    padding: 20px;
  }

  &-logo {
    width: 190px;
    margin-bottom: 30px;
    z-index: 9;
  }

  &-card {
    border-radius: 20px !important;
    width: 520px;

    @media screen and (max-width: 420px) {
      width: 100% !important;
    }

    .card-title {
      margin-bottom: 48px;
    }

    z-index: 9;
  }

  .gig-account-note {
    margin-top: 12px;
    color: white;

    a {
      color: white;
      font-weight: 800;
      margin-left: 5px;
    }
  }


}

.bottom-img-absolute {
  display: none !important;
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;