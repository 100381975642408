.alert-box {
  padding: 50px 0;
}

.text-me-link {
  border-top: 1px solid var(--natural-super-light-gray);
  display: flex;
  align-items: center;
  justify-content: center;

  &-form {
    padding: 50px 0;
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;