/* Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaisei+HarunoUmi:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --poppins: 'Poppins';
  --montserrat: 'Montserrat';
  --kaisei: 'Kaisei HarunoUmi', serif;
}

@montserrat: 'Poppins', sans-serif;
@montserrat: 'Montserrat', sans-serif;
@kaisei: 'Kaisei HarunoUmi', serif;

@font-weight-thin: 100;
@font-weight-extra-light: 200;
@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@font-weight-extra-bold: 800;
@font-weight-black: 900;

:root {
  --font-weight-thin: @font-weight-thin;
  --font-weight-extra-light: @font-weight-extra-light;
  --font-weight-light: @font-weight-light;
  --font-weight-regular: @font-weight-regular;
  --font-weight-medium: @font-weight-medium;
  --font-weight-semi-bold: @font-weight-semi-bold;
  --font-weight-bold: @font-weight-bold;
  --font-weight-extra-bold: @font-weight-extra-bold;
  --font-weight-black: @font-weight-black;
}