.auth-header {
  background: transparent !important;
  height: auto !important;
  display: flex;
  justify-content: space-between;
  align-items: end;

  &-menu {
    width: 200px;
  }

  &-usermenu {
    background: transparent !important;
    border: none !important;

    li.ant-menu-submenu-selected {
      padding: 0px !important;
      color: white !important;

      .ant-menu-submenu-title {
        height: auto !important;
      }

      &::after {
        border: none !important;
      }
    }

    li.ant-menu-submenu-active {
      .ant-menu-submenu-title {
        color: white !important;
      }
    }
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;