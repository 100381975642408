.privacy-feature {
  min-height: 100vh;
  text-align: left;

  &-display {
    background-color: white;
    padding: 50px;
    border-radius: 20px;

    @media screen and (max-width: 420px) {
      width: 100%;
    }

  }

}

.privacy-device-feature {
  text-align: left;

  &-display {
    background-color: white;
    padding: 24px;
  }

}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;