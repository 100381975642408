.footer-menu {
  text-align: left;
  width: 100%;
  margin: 20px auto;
  width: 300px;
  z-index: 9;

  &-navitation {
    padding-bottom: 20px;

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      li {
        color: antiquewhite;
        font-size: 12px;
        font-weight: 400;

        a {
          color: white !important;
        }
      }
    }

  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;