.otp-feature {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  background-image: url('../../../assets/images/bg.png');
  background-attachment: fixed;
  // background: linear-gradient(177deg, rgba(255,101,99,0.6489189425770308) 0%, rgba(20,117,188,1) 50%);
  text-align: center;

  @media screen and (max-width: 420px) {
    padding: 20px;
  }


  &-logo {
    width: 190px;
    margin-bottom: 84px;
  }

  &-card {
    border-radius: 20px !important;
    width: 320px;

    .card-title {
      margin-bottom: 48px;
    }
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;