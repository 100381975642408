.dashboard-feature {
  // min-height: 100vh;
  text-align: left;

  @media screen and (max-width: 420px) {
    padding: 12px;
  }

  &-display {
    background-color: white;

    padding: 50px;
    border-radius: 20px;

    @media screen and (max-width: 420px) {
      width: 100%;
      padding: 24px;
    }

  }

  .wallet-type {
    padding: 20px;
  }

  .metamask {
    background-image: url('../../../assets/images/metamask.png') !important;
    background-repeat: no-repeat !important;
    background-size: 15px !important;
    background-position: 0 center !important;
  }

  .coinbase {
    background-image: url('../../../assets/images/coinbase.png') !important;
    background-repeat: no-repeat !important;
    background-size: 15px !important;
    background-position: 0 center !important;
  }

}

.about-device-feature {
  text-align: left;

  &-display {
    background-color: white;
    padding: 24px;
  }

}

.connect-wallet {
  &-button {
    border: 1px solid var(--natural-light-gray) !important;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 600 !important;
  }

  &-coinbase {
    background-image: url('../../../assets/images/coinbase.png') !important;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
    background-position: 12px center !important;
    position: relative;

    &::after {
      position: absolute;
      content: '\2605';
      display: 'block';
      font-size: 12px;
      color: var(--brand-primary-red);
      right: 20px;
      top: 50%;
      transform: translate(-50%, -50%);

    }
  }

  &-metamask {
    background-image: url('../../../assets/images/metamask.png') !important;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
    background-position: 12px center !important;
  }
}
@primary-color: #1475BC;@secondary-color: #FF6563;@link-color: #535353;